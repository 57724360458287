<template>
    <div class="container-inner">
        <div class="text-center">
            <div class="item-img margin-auto logo" v-lazy:background-image="getLazyLoadImg(slogo)"></div>
            <div class="mt-3 wt">{{$t('welcome.welcome')}}</div>
            <select v-if="!select_tid" class="choose_table form-control" @change="close_picker">
                <option value="0">{{ $t('welcome.selectTable') }}</option>
                <option v-bind:value="item.tid" v-for="item in table_list" v-bind:key="item.tid">
                    {{ item.tname }}
                </option>
            </select>
            <input v-if="false && select_tid" type="text" v-model="select_table_name" readonly="readonly" />
            <div class="tb-no">
                <span class="no" v-if="select_tid">{{ select_table_name }}</span>
            </div>
            <div class="tb">{{$t('welcome.table')}}</div>

            <div class="input_wrap">
                <div class="form-item" v-if="showPhone">
                    <select v-model="phoneCode" class="phone_code">
                        <option value="+1">+1</option>
                        <option value="+86">+86</option>
                    </select>
                    <input
                        class="phone_input"
                        type="text"
                        @focus="toggleFocus(true)"
                        @blur="toggleFocus(false)"
                        @keyup="input_change"
                        style="border-radius: 5px"
                        v-model="phone_num"
                        :placeholder="phonePlaceholder"
                    />
                </div>
                <div class="people_num" v-if="!people_num_had">
                    <input
                        type="number"
                        v-model="peopleNum"
                        min="1"
                        :placeholder="$t('welcome.number_guests_required')"
                        :readonly="people_num_readonly"
                    />
                </div>
            </div>

            <div class="form-submit">
                <a class="cta style3" :class="{ disabled: table_index < 0 }" @click="choose_table">
                    <img v-if="is_loading" class="loading" src="@/assets/images/loading.svg" alt="" />
                    <span v-else :data-bee="'module_id=button_click&item_id=start_to_order'">{{ $t('welcome.start_to_order') }}</span>
                </a>
            </div>

            <div class="verify-code" v-if="show_verify">
                <div class="content-zone">
                    <h1 class="wt text-lowercase">Verify Code</h1>
                    <div class="code-zone mt-3 font-weight-bolder">
                        <span style="letter-spacing: 15px; font-size: 2rem">{{ verify_code }}</span>
                        <span v-bind:style="{ visibility: cursor_style }">|</span>
                    </div>
                </div>
                <div class="keyboard-zone">
                    <div class="d-flex justify-content-around flex-wrap">
                        <a class="btn cta" @click="press_key(1)" data-val="1">
                            <p class="btn__label">1</p>
                        </a>
                        <a class="btn cta" @click="press_key(2)" data-val="2">
                            <p class="btn__label">2</p>
                        </a>
                        <a class="btn cta" @click="press_key(3)" data-val="3">
                            <p class="btn__label">3</p>
                        </a>
                        <a class="btn cta" @click="press_key(4)" data-val="4">
                            <p class="btn__label">4</p>
                        </a>
                        <a class="btn cta" @click="press_key(5)" data-val="5">
                            <p class="btn__label">5</p>
                        </a>
                        <a class="btn cta" @click="press_key(6)" data-val="6">
                            <p class="btn__label">6</p>
                        </a>
                        <a class="btn cta" @click="press_key(7)" data-val="7">
                            <p class="btn__label">7</p>
                        </a>
                        <a class="btn cta" @click="press_key(8)" data-val="8">
                            <p class="btn__label">8</p>
                        </a>
                        <a class="btn cta" @click="press_key(9)" data-val="9">
                            <p class="btn__label">9</p>
                        </a>
                        <a class="btn cta" @click="press_key('del')" data-val="del">
                            <p class="btn__label">
                                <span>⌫</span>
                            </p>
                        </a>
                        <a class="btn cta" @click="press_key(0)" data-val="0">
                            <p class="btn__label">0</p>
                        </a>
                        <a class="btn cta" @click="press_key('submit')" data-val="submit">
                            <p class="btn__label">
                                <span>✔</span>
                            </p>
                        </a>
                    </div>
                </div>
                <div class="verify-btn-zone d-flex justify-content-between">
                    <a class="cta style4" @click="do_cancel" type="default">Cancel</a>
                    <a class="cta style3 flex-shrink-1" @click="do_submit" type="default">Verify </a>
                </div>
            </div>
            <!-- <RecommendLayer v-show="showRecommend" :recommend-data="recommend_popup_setting" :close-fun="do_close" /> -->
            <div class="term-text">
                By joining in, you agree to Minitable Tech, Inc<br />
                <a href="http://info.minitable.net/termsofuse.html" target="_blank">Terms of Service</a> and
                <a href="http://info.minitable.net/privacypolicy.html" target="_blank">Privacy Policy</a>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.logo{
    border-radius: 100%;
    overflow: hidden;
    box-shadow: 0px 5px 10px #b6b6b6;
}
.input_wrap {
    width: 80vw;
    margin: 0 auto;
    max-width: 700px;
    .form-item {
        display: flex;
        align-items: center;
        padding-bottom: 0;
        margin-bottom: 1rem;
        .phone_input {
            width: 100%;
            margin-top: 0;
            flex-grow: 1;
            max-width: none;
        }
    }
    .people_num {
        margin-top: 1rem;
        margin-bottom: 20px;
        input {
            width: 100%;
            border-radius: 5px;
            padding-left: 15px;
            margin-top: 0;
        }
    }
}
h3 {
    color: #ffba69;
    /* letter-spacing: 25px; */
    padding-left: 9px;
}
.phone_code {
    width: 48px;
    height: 35px;
    border-radius: 5px;
    margin-right: 2px;
}

.container input {
    width: 99%;
    color: #333333;
    height: 35px;
    border: 1px solid #000;
    /* border: 1px solid #ccc; */
    margin-top: 20px;
    font-size: 16px;
}

.container .picer-zone {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 50;
}

.container .mask {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #000;
    opacity: 0.4;
    z-index: 10;
}

.keyboard-zone {
    .btn {
        width: 33%;
        display: inline-block;
        padding: 0;
    }
    p {
        font-size: 25px;
    }
}

.verify-btn-zone {
    a {
        padding: 15px;
    }
}

.slogo {
    height: 80px;
}
.margin-auto {
    margin: 0 auto;
}
@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.loading {
    position: static;
    background-color: transparent;
    color: white;
    width: 30px;
    height: 30px;
    animation: rotate 2s infinite;
    animation-timing-function: linear;
}
</style>

<script>
import Vue from 'vue';
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import { lazyLoadImg } from '../kits/tools';

export default {
    name: 'SelectTable',
    components: {},
    data() {
        return {
            showPhone: true,
            peopleNum: '',
            phoneCode: '+1',
            show_picker: false,
            shared_tid: this.$route.query.tid || '',
            temp_tid: '',
            uname: '',
            verify_code: '',
            show_verify: false,
            cursor_style: 'visiable',
            timer: -1,
            select_tid: this.$route.query.tid || '',
            select_p: this.$route.query.p || '',
            phone_num: '',
            is_focus: false,
            showLaunch: true,
            showRecommend: false,
            store_pic: '',
            is_loading: false,
            people_num_had: false,
        };
    },
    beforeCreate() {
        if (this.$route.query.sid != '' && this.$route.query.sid != window.localStorage.getItem('client_sid')) {
            return;
        }
        // if (this.$store.state.app.shared_tid !== '') {
        //     this.$router.replace({ path: '/menu' });
        // }
    },
    created() {
        this.initData(this.$route.query.sid);

        this.timer = setInterval(() => {
            if (this.cursor_style) {
                this.cursor_style = this.cursor_style === 'visible' ? 'hidden' : 'visible';
            }
        }, 600);

        const cartId = this.$store.state.app.shared_cart_id;
        if (cartId !== '') {
            this.getSharedCart({
                cartid: cartId,
                router: this.$router
            });
        }
    },
    beforeDestroy() {
        clearInterval(this.timer);
    },
    watch: {
        sharedTable(val) {
            if (val && val.people_num > 0) {
                this.peopleNum = val.people_num;
                this.people_num_had = true;
            } else {
                this.peopleNum = '';
                this.people_num_had = false;
            }
        },
        table_list(list) {
            if (list.length > 0) {
                if (this.select_tid && this.table_list.findIndex((table) => table.tid === this.select_tid) < 0) {
                    this.$router.push({ path: '/closed_table?tid=' + this.select_tid + '&sid=' + this.$route.query.sid});
                }
            }
        },
        shared_tid: {
            immediate: true,
            handler: async function (val){
                if(!val) return;
                const phone = await this.get_phone_by_tid({
                    sid: this.$route.query.sid,
                    tid: val
                });
                if(phone) {
                    this.showPhone = false;
                }else {
                    this.showPhone = true;
                }
            }
        },
        // 扫码同一张桌子，并且购物车没有关闭才跳转到menu页面
        'cart_data.user_count'(val) {
            if (val > 0) {
                this.$router.replace({ path: '/menu' });
            }
        }
    },
    computed: {
        phonePlaceholder() {
            if (this.$store.state.app.store_data.phone_num_required) {
                return this.$t('welcome.phone_required');
            } else {
                return this.$t('welcome.phone_optional')
            }
        },
        // peopleNumPlaceholder() {
        //     const require = this.people_num_required;
        //     if (require == 1) {
        //         return this.$t('welcome.number_guests_required');
        //     } else if (require == 2) {
        //         return this.$t('welcome.number_guests_optional');
        //     }

        //     return '';
        // },
        back_style() {
            if (this.spic === '') {
                return {};
            }

            return {
                backgroundImage: 'url(' + this.spic + ')',
                backgroundRepeat: 'no-repeat',
                height: '100%',
                backgroundSize: 'cover'
            };

            return (
                'background-image: url(' +
                this.spic +
                ');background-repeat: no-repeat;height: 100%;background-size: cover;'
            );
        },
        ...mapState({
            people_num_required: (state) => state.app.store_data.people_num_required,
            cart_data: (state) => state.app.cart_data
        }),
        ...mapGetters({
            spic: 'app/get_store_pic',
            slogo: 'app/get_store_slogo',
            language: 'app/get_language'
        }),
        slots() {
            let temp_slots = [
                {
                    flex: 1,
                    values: this.$store.state.selecttable.table_list,
                    className: 'slot1',
                    textAlign: 'center'
                }
            ];

            return temp_slots;
        },
        sharedTable() {
            if (this.$store.state.selecttable.table_list && this.shared_tid) {
                return this.$store.state.selecttable.table_list.filter((item) => item.tid === this.shared_tid)[0];
            }
            return null;
        },
        people_num_readonly() {
            if (this.sharedTable) {
                if (this.sharedTable.people_num > 0) {
                    return true;
                }
            }
            return false;
        },
        table_list() {
            return this.$store.state.selecttable.table_list;
        },
        table_index() {
            let temp_index = -1;
            for (let i = 0; i < this.table_list.length; i++) {
                if (this.table_list[i].tid == this.shared_tid) {
                    temp_index = i;
                    break;
                }
            }
            return temp_index;
        },
        table_name() {
            let temp_name = '';
            for (let i = 0; i < this.table_list.length; i++) {
                if (this.table_list[i].tid == this.shared_tid) {
                    temp_name = this.table_list[i].tname;
                    break;
                }
            }

            return temp_name;
        },
        select_table_name() {
            let temp_name = '';
            for (let i = 0; i < this.table_list.length; i++) {
                if (this.table_list[i].tid == this.select_tid) {
                    temp_name = this.table_list[i].tname;
                    break;
                }
            }

            if (temp_name) {
                return '#' + temp_name;
            }

            return '';
        }
    },
    methods: {
        ...mapActions({
            set_msg: 'app/setToast'
        }),
        onValuesChange(picker, values) {
            picker.setSlotValue(1, values[0].val);
            this.temp_tid = values[0].tid;
        },
        do_show_picker(e) {
            this.show_picker = true;
        },
        close_picker(e) {
            // this.show_picker = false;
            // this.shared_tid = this.temp_tid;
            this.shared_tid = e.target.value;
        },
        choose_table(e) {
            if (this.table_index === -1) {
                this.set_msg({ msg: 'Wrong table!' });
                return;
            }
            let pswd = this.table_list[this.table_index].pswd || '';

            let email_reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

            if (this.uname != '' && !email_reg.test(this.uname)) {
                this.set_msg({ msg: 'Wrong email!' });
                return;
            }

            if (!this.people_num_had) {
                if (!/^[1-9]\d*/.test(this.peopleNum)) {
                    this.set_msg({
                        msg: this.$t('welcome.input_number_guests')
                    });
                    return;
                }
            }

            let temp_phone_num = this.phone_num.replace(/[\-\(\)]/g, '');
            if (this.showPhone && this.$store.state.app.store_data.phone_num_required && temp_phone_num === '') {
                this.set_msg({
                    msg: this.$t('welcome.input_phone')
                });
                return;
            }
            let _code = '';

            if (temp_phone_num != '') {
                temp_phone_num = this.phoneCode + ' ' + temp_phone_num;
                _code = temp_phone_num.substr(-4);
            } else {
                _code = Math.round(Math.random() * 100);
                if (_code < 10) {
                    _code = '0' + _code;
                }
            }

            if (this.is_loading) {
                return;
            }

            if (pswd == this.select_p) {
                this.is_loading = true;
                this.joinTable({
                    sid: this.$route.query.sid,
                    uid: this.$store.state.app.uid,
                    tid: this.table_list[this.table_index].tid,
                    tname: this.table_list[this.table_index].tname,
                    router: this.$router,
                    pswd: this.select_p,
                    uname: 'Guest' + _code,
                    type: 'scan',
                    phone_num: temp_phone_num,
                    people_num: this.peopleNum
                });
                return;
            }

            if (pswd === '') {
                this.is_loading = true;
                this.joinTable({
                    sid: this.$route.query.sid,
                    uid: this.$store.state.app.uid,
                    tid: this.table_list[this.table_index].tid,
                    tname: this.table_list[this.table_index].tname,
                    router: this.$router,
                    pswd: '',
                    uname: 'Guest' + _code,
                    type: 'scan',
                    phone_num: temp_phone_num,
                    people_num: this.peopleNum
                });
            } else {
                this.show_verify = true;
            }
        },
        ...mapActions({
            initData: 'selecttable/initData',
            joinTable: 'app/joinTable',
            getSharedCart: 'app/get_shared_cart',
            get_phone_by_tid: 'app/get_phone_by_tid'
        }),
        ...mapMutations({
            setSharedTimer: 'app/APP_SET_SHARED_TIMER'
        }),
        press_key(val) {
            if (val === 'del') {
                this.verify_code = this.verify_code.substr(0, this.verify_code.length - 1);
            } else if (val === 'submit') {
                this.do_submit();
            } else {
                if (this.verify_code.length < 2) {
                    this.verify_code = this.verify_code + '' + val;
                }
            }
        },
        do_cancel(e) {
            this.show_verify = false;
            this.verify_code = '';
        },
        do_submit(e) {
            if (this.table_index === -1) {
                return;
            }
            let pswd = this.table_list[this.table_index].pswd || '';
            if (pswd === this.verify_code) {
                this.do_join(this.verify_code);
            } else {
                this.set_msg({ msg: 'Wrong Password' });
            }
        },
        do_join(pswd) {
            if (this.table_index < 0) {
                return;
            }

            if (this.is_loading) {
                return;
            }

            this.is_loading = true;

            this.joinTable({
                sid: this.$route.query.sid,
                uid: this.$store.state.app.uid,
                tid: this.table_list[this.table_index].tid,
                tname: this.table_list[this.table_index].tname,
                router: this.$router,
                pswd: pswd,
                uname: this.uname,
                type: 'scan'
            });
        },
        input_change(e) {
            if (e.target.value != '' && e.keyCode != 8) {
                let temp = e.target.value.replace(/\D/g, '');
                this.phone_num = temp.replace(/(\d{1,3})(\d{1,3})?(\d{1,4})?/, function (a, b, c, d) {
                    if (b) {
                        if (!c) {
                            return '(' + b + ')';
                        }
                        if (!d) {
                            return '(' + b + ')' + c;
                        }
                        return '(' + b + ')' + c + '-' + d;
                    }
                });
            }
        },
        toggleFocus(_type) {
            this.is_focus = _type;
        },
        do_close() {
            this.showRecommend = false;
        },
        getLazyLoadImg(img) {
            return lazyLoadImg(img);
        }
    }
};
</script>
